/**
 * Ironstrap ES6 Class - Sample
 */
class IronstrapApp
{
    constructor(msg)
    {
        /**
         * Setup some data
         */
        this.msg = msg;
    }

    register()
    {
        console.log(this.msg);
        console.log('Ironstrap App Initialized');
    }
}
export default IronstrapApp;