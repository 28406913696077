/*--------------------------------------------------------------
# Import Style
--------------------------------------------------------------*/
import '../sass/app.scss';

/*--------------------------------------------------------------
# Import Node Modules
--------------------------------------------------------------*/
import LazyLoad from "vanilla-lazyload";
import Swiper from 'swiper/dist/js/swiper.js';
import superfish from 'superfish/dist/js/superfish';

/*--------------------------------------------------------------
# Import Custom Modules
--------------------------------------------------------------*/
import IronstrapApp from './modules/ironstrap-app.js';

/*--------------------------------------------------------------
# Import Vue Components
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# ES6
--------------------------------------------------------------*/
const app = new IronstrapApp('Ironstrap Framework v.2.0.0');
app.register();

// Lazy Load
const ironLazyLoad = new LazyLoad(
    {
        elements_selector: '.iron-lazy'
    }
);

document.addEventListener('DOMContentLoaded', function() {

    // Content Slider
    const contentSlider = new Swiper('.site-content-slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 30,
        autoHeight: true,
        loop: true,
        loopAdditionalSlides: 3,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: jQuery(this).find('.swiper-button-next'),
            prevEl: jQuery(this).find('.swiper-button-prev'),
        },
        pagination: {
            el: '.swiper-pagination',
        },
    });

    // Hero Slider
    const heroSlider = new Swiper('.site-hero .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        autoHeight: true,
        loop: true,
        loopAdditionalSlides: 3,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: function() {
                ironLazyLoad.update();
            },
            slideChange: function() {
                ironLazyLoad.update();
            },
        },
    });

    jQuery(window).load(function() {
        if (jQuery('.site-hero').length) {
            heroSlider.update();
        }
    });

});

/*--------------------------------------------------------------
# jQuery
--------------------------------------------------------------*/
jQuery(document).ready(function($) {

    'use strict';

    // Mobile Menu
    $('.site-menu-btn').on('click', function() {
        $('.site-main-navigation-mobile').slideToggle(300);
    });
    $('.site-main-navigation-mobile .menu').superfish(
        {
            speed: 300,
            speedOut: 300,
            animation: {height: 'show'},
            animationOut: {height: 'hide', opacity: 'hide'}
        }
    );

    // Site Tabs
    $('.site-tabs .e-tabs a').each(function() {
        let elem = $(this);
        let id = elem.attr('href');
        if ($(id).length) {
            $(id).prepend('<div class="e-accord">'+ elem.text() +'</div>');
        }
        elem.parents('.e-tabs').next().find('.e-accord').addClass('active').next().css('display', 'block');
    });
    $('.site-tabs .e-tabs a').on('click', function(e) {
        e.preventDefault();
        let elem = $(this);
        let id = elem.attr('href');
        if ($(id).length) {
            elem.parent().siblings().removeClass('active');
            elem.parent().addClass('active');
            $(id).siblings().not(elem.parents('.e-tabs')).hide();
            $(id).show();
        }
    });
    /*
    $('.site-tabs .e-accord').on('click', function() {
        let elem = $(this);
        let entry = elem.next();
        elem.toggleClass('active');
        entry.slideToggle();
    });
    */

    // Header Search
    $('.site-header-search').on('click', function(e) {
        e.stopPropagation();
        let searchModal = $('.site-search-modal');
        let searchInput = searchModal.find('input[name="s"]');
        searchModal.addClass('o-active_js');
        setTimeout(function() {
            searchInput.focus();
        }, 160);
        searchModal.find('.e-modal').on('click', function(e) {
            e.stopPropagation();
        });
        $('html, body').on('click', function() {
            searchModal.removeClass('o-active_js');
        });
    });

    // Recipe Ingredients - Print Button
    $('.site-single-dprecipe .e-post-content h2').each(function() {
        let text = $(this).text();
        $(this).wrap('<div class="row align-items-center justify-content-between"><div class="col-md"></div></div>')
        if (text === 'Ingredients') {
            $(this).parent().parent().append('<div class="col-md-auto"><div class="e-post-print-recipe"><a class="btn btn-primary btn-sm" href="#">Print Recipe</a></div></div>');
        }
    });

    // Print Recipe
    $('.e-post-print-recipe > a').on('click', function(e) {
        e.preventDefault();
        window.print();
    });

    // Product Tags Blocks - Modal
    $('.site-prod-tags-blocks .e-blocks a').on('click', function(e) {
        e.preventDefault();

        let elem = $(this);
        let tag_title = elem.data('title');
        let tag_slug = elem.data('slug');
        let modal = $('.site-modal');
        let entry = modal.find('.e-entry');
        let overlay = modal.find('.e-overlay');
        let close = modal.find('.e-close');
        let preloader = modal.find('.e-preloader');

        $('html').addClass('overflow-hidden');
        modal.addClass('o-active_js');
        preloader.addClass('o-active_js');

        $.ajax({
            url: ironajax.ajax_url,
            type: 'post',
            dataType: 'json',
            data: {
                action: 'product_tag_modal',
                data: {
                    tag_title: tag_title,
                    tag_slug: tag_slug,
                }
            }
        })
        .done(function(response) {
            console.log(response);
            
            if (response.data.html) {
                preloader.removeClass('o-active_js');
                entry.html(response.data.html);
                modal.addClass('o-entry_js');
                ironLazyLoad.update();
            }

            close.on('click', function() {
                modal.removeClass('o-entry_js');
                setTimeout(function() {
                    modal.removeClass('o-active_js');
                }, 300);
                setTimeout(function() {
                    $('html').removeClass('overflow-hidden');
                }, 600);
            });
        })
        .fail(function(response) {
            console.log(response);
            preloader.removeClass('o-active_js');
            modal.removeClass('o-entry_js');
            setTimeout(function() {
                modal.removeClass('o-active_js');
            }, 300);
            setTimeout(function() {
                $('html').removeClass('overflow-hidden');
            }, 600);
        });
    });

    // Posts Load More
    $('.site-posts-load-more > a').on('click', function(e) {
        e.preventDefault();

        let button = $(this);
        let entry = $(this).parents('.site-posts-load-container').find('.site-posts-load-entry');
        let atts = button.data('atts');
        let args = button.data('args');

        args.paged = args.paged + 1;
        button.data('args', args);
        
        button.text('Loading');
        button.addClass('disabled');

        $.ajax({
            url: ironajax.ajax_url,
            type: 'post',
            dataType: 'json',
            data: {
                action: 'posts_load_more',
                data: {
                    args: args,
                    atts: atts,
                }
            }
        })
        .done(function(response) {
            if (response.data.html) {
                entry.append(response.data.html);
                button.text('Load More');
                button.removeClass('disabled');
                ironLazyLoad.update();
            } else {
                button.text('Done');
                setTimeout(function() {
                    button.parent().slideUp(400);
                }, 500);
            }
        })
        .fail(function(response) {
            console.log(response);
            button.removeClass('disabled');
        });
    });

    // Main Navigation
    $('.site-main-navigation > .menu').superfish({
        popUpSelector: '.e-dropdown, .e-megadrop',
        speed: 175,
        speedOut: 175,
        delay: 150,
        animation: {height: 'show'},
        animationOut: {height: 'hide', opacity: 'hide'}
    });

    // Product Tags Blocks - List
    $('.site-prod-tags-blocks .e-list .e-label').on('click', function() {
        let elem = $(this);
        elem.toggleClass('o-active_js');
        elem.next().slideToggle(300);
    });

    // Header Sticky Scroll
    function sticky_header() {
        var $scroll_amount = 200;
        if($(window).scrollTop() >= $scroll_amount) {
            $('body').addClass('o-header-sticky_js');
        } else {
            $('body').removeClass('o-header-sticky_js');
        }
    }
    sticky_header();
    $(window).scroll(function() {
        sticky_header();
    });

    // Woocommerce Product Search Filter
    $('.site-woocommerce-search-filter .e-tag input').on('change', function() {
        let elem = $(this);
        elem.parents('.e-tag').toggleClass('o-active_js');
    });
    $('.site-woocommerce-search-filter .e-a-tag input').on('change', function() {
        let elem = $(this);
        elem.parents('.e-a-tag').toggleClass('o-active_js');
    });
    $('.site-woocommerce-search-filter .e-advanced-title').on('click', function() {
        let elem = $(this);
        let advancedForm = elem.parent().next();
        advancedForm.slideToggle(300);
    });
    $('.site-woocommerce-search-filter-m').on('click', function() {
        let elem = $(this);
        let searchForm = elem.next();
        elem.toggleClass('o-active_js');
        searchForm.slideToggle(300);
    });

    // Variations Clear
    $(".variations_form").on("reset_data", function () {
        $('.site-prod-variation-blocks .e-options').each(function() {
            //$(this).attr('selected', false);
        });
    });

    // Product Variation Blocks - Options
    $('.site-prod-variation-blocks .e-options').on('click', function() {
        let elem = $(this);
        let elemName = elem.parents('.site-prod-variation-blocks').data('name');
        let value = elem.attr('value');
        let others = elem.parents('.site-prod-variation-blocks').find('.e-options').not(elem);
        let paSizes = $('select[name="' + elemName + '"]');

        //elem.attr('selected', 'selected');
        //others.attr('selected', false);
        paSizes.val(value).trigger('change');
    });

    // Variations on Update
    $(".variations_form").on( "woocommerce_update_variation_values", function () {
        $('.site-prod-variation-blocks .e-options').attr('selected', false);
        // Enable / Disable - Based on variations
        let swatches = $('.site-prod-variation-blocks');
        swatches.find('.e-options').removeClass('o-disabled_js');
        $('.variations select').each(function() {
            let this_attr_name = $(this).data('attribute_name');
            let this_variation = $(this).val();
            //console.log(this_attr_name);
            //console.log(this_variation);
            if (this_variation) {
                $('.site-prod-variation-blocks[data-name="'+ this_attr_name +'"]').find('.e-options[value="'+ this_variation +'"]').attr('selected', 'selected');
                $('.site-prod-variation-blocks[data-name="'+ this_attr_name +'"]').find('.e-options[value="'+ this_variation +'"]').parent().siblings().find('.e-options').attr('selected', false);
            }
        });
        swatches.each(function(){
            let select = $(this).next('select');
            $(this).find('.e-options').each(function(){
                if (!(select.find('option[value="'+ $(this).attr('value') +'"]').length > 0)) {
                    $(this).addClass('o-disabled_js');
                    $(this).attr('selected', false);
                }
            });
        });
    });

    /*
    $('.composite_data').on('wc-composite-initializing', function(event, composite) {
        console.log('super');
    });
    */

    // Product Tabs
    $('.woocommerce-Tabs-panel').each(function() {
        let elem = $(this);
        let title = elem.parent().find('ul.tabs').find('#'+elem.attr('aria-labelledby')).children().text();

        elem.prepend('<div class="site-woo-tabs-panel-title">'+ title + '</div>');
    });

    // Mix and Match Table Wrapper
    $('body.single-product table.mnm_table').wrap('<div class="site-mnm-table-wrap"></div>');

    // Back Top
    $('.site-back-to-top').on('click', function() {
        $('html, body').animate(
            {
                scrollTop: 0
            },
            400
        );
    });
    
});